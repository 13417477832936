<template>
  <div class="contact-content-wrapper">
    <flowers-falling/>
    <greetings-list/>
    <div class="container-fluid contact-page" ref="contacts">
      <p class="contacts-title">Let’s work together!</p>
      
      <form @submit.prevent="onSubmit" method="post" class="contact-form">
        <div class="form-group">
          <input type="text" name="name" class="form-control" placeholder="Name" v-model="form.name" :class="Object.keys(form.notifications.errors).includes('name') && `-error`">
          <input type="text" class="secondname" name="secondname" placeholder="Second name">
          <span data-validate-for="name"></span>
        </div>
        <div class="form-group">
          <input type="email" name="email" class="form-control" placeholder="Email" v-model="form.email" :class="Object.keys(form.notifications.errors).includes('email') && `-error`">
          <span data-validate-for="email"></span>
        </div>
        <div class="form-group">
          <textarea name="message" class="form-control" placeholder="Message" rows="10" v-model="form.message" :class="Object.keys(form.notifications.errors).includes('message') && `-error`"></textarea>
          <span data-validate-for="message"></span>
        </div>
        <div :id="`${nameFieldName}_wrap`" style="display:none;" aria-hidden="true">
          <input :id="nameFieldName" :name="nameFieldName" type="text" autocomplete="nope" tabindex="-1">
          <input :name="validFromFieldName" type="text" :value="encryptedValidFrom" autocomplete="nope" tabindex="-1">
        </div>
        <!--vue-recaptcha v-show="showRecaptcha" 
          sitekey="recaptchaKey"
          size="normal" 
          theme="light"
          hl="tr"
          :loading-timeout="loadingTimeout"
          @verify="recaptchaVerified"
          @expire="recaptchaExpired"
          @fail="recaptchaFailed"
          @error="recaptchaError"
          ref="vueRecaptcha">
        </vue-recaptcha-->
        <div v-text="form.notifications.success" class="form-message-success"></div>
        <ul v-if="Object.keys(form.notifications.errors).length > 0" class="form-errors">
          <li v-for="error in form.notifications.errors" :key="error">{{ error }}</li>
        </ul>
        <div class="consent">
          All the fields are required. By sending the form you agree to the <router-link :to="{name: 'Terms'}" v-bind:style="{cursor: selectedCursor}">Terms & Conditions</router-link> and <router-link :to="{name: 'Terms'}" v-bind:style="{cursor: selectedCursor}">Privacy Policy</router-link>.
        </div>
        <div class="button-wrapper">
          <button type="submit" class="btn-round">
            <span>send message</span>
          </button>
        </div>
        <p class="contacts contacts-email">
          <span>SAY HI: </span>
          <a :href="`mailto:`+email" v-text="email"></a>
        </p>
        <ul class="socials">        
          <template v-for="(network, index) in socialNetworks" :key="index">          
            <li>
              <a :href="network.link" target="_blank">{{ network.title }}</a>
            </li>
          </template>
        </ul>
      </form>

      <div class="timer">
        <div v-text="datestamp"></div>
        <div v-text="timestamp"></div>
      </div>
    </div>
    <footer-block/>
  </div>
</template>

<script>
import gsap from 'gsap'
import api from '@/api'
import FlowersFalling from '@/components/Flowers'
import GreetingsList from '@/components/GreetingsList'
// import vueRecaptcha from 'vue3-recaptcha2';

export default {
  name: 'ContactPage',
  page: {
    title: 'Contacts'
  },
  components: {
    GreetingsList,
    FlowersFalling
    // vueRecaptcha
  },
  data () {
    return {
      email: '',
      socialNetworks: [],
      timestamp: '',
      nameFieldName: '',
      validFromFieldName: '',
      encryptedValidFrom: '',
      form: {
        name: '',
        email: '',
        message: '',
        notifications: {
          success: '',
          errors: {}
        }
      }
      // recaptchaKey: '6LfhZWQpAAAAAA4pkU7XdPes2SwnoEj4FeHqDNMs',
      // showRecaptcha: true,
      // loadingTimeout: 30000 // 30 seconds
    }
  },
  computed: {
    axiosParams() {
      const params = new URLSearchParams()
      params.append('slug', 'contacts')
      return params
    }
  },
  mounted () {
    api.get('/pages', {
      params: this.axiosParams
    }).then(response => {
      const data = response.data[0]
      if (typeof data.viewbag !== 'undefined') {
        const viewbag = data.viewbag
        this.email = viewbag.email
        this.socialNetworks = viewbag.social_networks || []
        if (typeof data.honeypot !== 'undefined') {
          this.nameFieldName = data.honeypot.nameFieldName
          this.validFromFieldName = data.honeypot.validFromFieldName
          this.encryptedValidFrom = data.honeypot.encryptedValidFrom
        }
      }
    })

    const interval = setInterval(() => {
      if (this.$refs.contacts) {
        const target = this.$refs.contacts;
        gsap.from(target.children,
          {
            y: 50,
            ease: 'Power0.easeNone',
            autoAlpha: 0,
            duration: 0.8,
            stagger: 0.5,
            delay: 3.8
          }
        )

        clearInterval(interval)

        setTimeout(() => {
          document.querySelector('.wrapper').classList.add('-show-all-content')
        }, 4000)
      }
    }, 50)    
  },
  created() {
    setInterval(this.getNow, 1000)
  },
  methods: {
    getNow: function() {
      const today = new Date()
      const date = new Intl.DateTimeFormat('en-GB', {
        weekday: 'short',
        // year: "numeric",
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      }).format(today)
      const splitted = date.split(', ')
      this.datestamp = splitted[0] + ' ' + splitted[1]
      this.timestamp = 'GMT+2 ' + splitted[2]
    },
    async onSubmit (e) {
      try {
        const form = e.target
        const formData = new FormData(form)

        if (!this.validateForm()) {
          return
        }
        const response = await this.postData(formData)
        if (response.data.success) {
          form.reset()
          this.form.notifications.success = response.data.success
        } 
      } catch (e) {
        console.log(e)
      }
    },
    postData(formData) {      
      const response = api.post('/feedback/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: formData
      })
      return response
    },
    validateForm() {
      const form = this.form
      this.form.notifications.errors = {}
      if (!form.name) {
        this.form.notifications.errors.name = "*Name field cannot be blank"
      }
      if (!form.email) {
        this.form.notifications.errors.email = "*Email field cannot be blank"
      }
      if (!form.message) {
        this.form.notifications.errors.message = "*Message field cannot be blank"
      }

      if (Object.keys(this.form.notifications.errors).length > 0) {
        return false
      }

      return true
    }
    // recaptchaVerified(response) {
    // },
    // recaptchaExpired() {
    //   this.$refs.vueRecaptcha.reset()
    // },
    // recaptchaFailed() {
    // },
    // recaptchaError(reason) {
    // }
  }
}
</script>
