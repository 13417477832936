import * as THREE from 'three'
import * as dat from 'dat.gui'
import { EffectComposer, Pass, RenderPass, ShaderPass, TexturePass, ClearPass, MaskPass, ClearMaskPass, CopyShader } from 'postprocessing'
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls'
import Item from './item'
const createInputEvents = require('simple-input-events')

export default class Sketch {
  constructor(el) {
    this.eventEmitter = createInputEvents(el.parentNode)
    this.scene = new THREE.Scene()
    this.items = []

    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true
    })
    this.container = el
    const bounds = el.getBoundingClientRect()
    this.width = Math.round(bounds.width)// + 15 // was causing 1px black border
    this.height = Math.round(bounds.height)// + 15

    this.renderer.setPixelRatio(1)
    this.renderer.setSize(this.width, this.height)
    this.renderer.sortObjects = false
    this.renderer.setClearColor( 0x000000, 0 )
    this.renderer.outputEncoding = THREE.sRGBEncoding
    this.container.appendChild(this.renderer.domElement)

    this.camera = new THREE.PerspectiveCamera(70, this.width / this.height, 100, 1000)
    this.cameraDistance = 400
    this.camera.position.set(0, 0, this.cameraDistance)
    this.camera.lookAt(0, 0, 0)
    this.time = 0
    this.speed = 0
    this.targetSpeed = 0
    this.mouse = new THREE.Vector2()
    this.followMouse = new THREE.Vector2()
    this.prevMouse = new THREE.Vector2()
    this.paused = false
    this.composerPass()
    this.setupResize()
    this.addObjects()
    this.mouseMove()

    this.controls = new OrbitControls(this.camera, this.renderer.domElement)
    this.resize()
    this.render()
    this.createItems(el.querySelectorAll('img'))
    // this.settings()
    requestAnimationFrame(() => this.animate())
  }

  settings() {
    let that = this;
    this.settings = {
      radius: 0.1,
      power: 0.5,
      setPower: (v) => {
        that.customPass.fullscreenMaterial.uniforms.uPower.value = v
      },
      setRadius: (v) => {
        that.customPass.fullscreenMaterial.uniforms.uRadius.value = v
      },
      colorful: () => {
        that.customPass.fullscreenMaterial.uniforms.uType.value = 0
      },
      zoom: () => {
        that.customPass.fullscreenMaterial.uniforms.uType.value = 1
      },
      random: () => {
        that.customPass.fullscreenMaterial.uniforms.uType.value = 2
      },
      water: () => {
        that.customPass.fullscreenMaterial.uniforms.uType.value = 3
      }
    };
    this.gui = new dat.GUI();
    // this.gui.add(this.settings, "progress", -1, 2, 0.01);
    // this.gui.add(this.settings, "velo", 0, 1, 0.01);
    // this.gui.add(this.settings, "colorful");
    // this.gui.add(this.settings, "zoom");
    this.gui.add(this.settings, 'power', 0.1, 10).onChange((v) => this.settings.setPower(v));
    this.gui.add(this.settings, 'radius', 0.05, 0.3).onChange((v) => this.settings.setRadius(v));
    this.gui.add(this.settings, 'random');
    this.gui.add(this.settings, 'water');
  }

  mouseMove() {
    this.eventEmitter.on('move', ({ position, event, inside, dragging }) => {
      const rect = event.target.getBoundingClientRect()
      const xShiftPx = this.getTranslateX(this.container)
      this.mouse.x = ( position[0] + xShiftPx - ( this.container.id==='canvasWrapper2' ? this.width : 0 ) ) / this.width
      this.mouse.y = 1 - ( position[1] / rect.height)
    })
  }

  getTranslateX(el) {
    const style = window.getComputedStyle(el)
    const matrix = style.transform || style.webkitTransform || style.mozTransform
    if (matrix === '' || matrix === 'none' || typeof matrix === 'undefined') return 0

    const matrixValues = matrix.match(/matrix.*\((.+)\)/)[1].split(', ')
    return -matrixValues[4]
  }

  setupResize() {
    window.addEventListener('resize', this.resize.bind(this))
  }

  resize() {
    const bounds = this.container.getBoundingClientRect()
    this.width = bounds.width
    this.height = bounds.height - 2
    this.renderer.setSize(this.width, this.height)
    this.camera.aspect = this.width / this.height
    this.camera.fov = 2 * Math.atan(this.width / this.camera.aspect / (2 * this.cameraDistance)) * (180 / Math.PI)
    this.customPass.fullscreenMaterial.uniforms.resolution.value.y = this.height / this.width;
    this.camera.updateProjectionMatrix()
  }

  addObjects() {
    let that = this
    this.geometry = new THREE.PlaneBufferGeometry(1, 1, 80, 80)
    this.material = new THREE.ShaderMaterial({
      extensions: {
        derivatives: '#extension GL_OES_standard_derivatives : enable'
      },
      side: THREE.DoubleSide,
      uniforms: {
        time: { type: 'f', value: 0 },
        progress: { type: 'f', value: 0 },
        angle: { type: 'f', value: 0 },
        texture1: { type: 't', value: null },
        texture2: { type: 't', value: null },
        resolution: { type: 'v4', value: new THREE.Vector4() },
        uvRate1: { value: new THREE.Vector2(1, 1) }
      },
      transparent: true,
      alpha: true,
      premultipliedAlpha: true,
      vertexShader: this.vertexShader(),
      fragmentShader: this.fragmentShader()
    })
  }

  createItems(images) {
    let elementId = this.container.id
    images.forEach(image => {
      this.items.push(new Item(image, this, 0, elementId)) // was 3rd this.renderedStyles.translationY.value
    })
  }

  createMesh(o) {
    let material = this.material.clone()
    let texture = new THREE.TextureLoader().load(o.src)
    texture.needsUpdate = true

    let imageAspect = o.iHeight / o.iWidth
    let a1
    let a2
    if (o.height / o.width > imageAspect) {
      a1 = (o.width / o.height) * imageAspect
      a2 = 1
    } else {
      a1 = 1
      a2 = o.height / o.width / imageAspect
    }
    texture.minFilter = THREE.LinearFilter

    material.uniforms.resolution.value.x = o.width
    material.uniforms.resolution.value.y = o.height
    material.uniforms.resolution.value.z = a1
    material.uniforms.resolution.value.w = a2
    material.uniforms.progress.value = 0
    material.uniforms.angle.value = 0.3
    material.uniforms.texture1.value = texture
    material.uniforms.texture1.value.needsUpdate = true
    let mesh = new THREE.Mesh(this.geometry, material)
    mesh.scale.set(o.width, o.height, o.width / 2)
    mesh.clipShadows = true

    return mesh
  }

  composerPass() {
    this.composer = new EffectComposer(this.renderer)
    this.renderPass = new RenderPass(this.scene, this.camera)
    this.composer.addPass(this.renderPass)

    const counter = 0.0
    const shaderMaterial = new THREE.ShaderMaterial({
      uniforms: {
        'tDiffuse': { value: null },
        'distort': { value: 0 },
        'resolution': { value: new THREE.Vector2(1, this.width / this.height) },
        'uMouse': { value: new THREE.Vector2(-10, -10) }, 
        'uVelo': { value: 0 },
        'uRadius': { value: 0.22 },
        'uScale': { value: 0 },
        'uType': { value: 2 },
        'uPower': { value: 0.1 },
        'time': { value: 0 }
      },
      vertexShader: this.postVertexShader(),
      fragmentShader: this.postFragmentShader()
    })

    this.customPass = new ShaderPass(shaderMaterial, 'tDiffuse')
    this.customPass.renderToScreen = true
    this.composer.addPass(this.customPass)
  }

  getSpeed() {
    this.speed = Math.sqrt( (this.prevMouse.x - this.mouse.x) ** 2 + (this.prevMouse.y - this.mouse.y) ** 2 )
    this.targetSpeed -= 0.1 * (this.targetSpeed - this.speed) 
    this.followMouse.x -= 0.1 * (this.followMouse.x - this.mouse.x) 
    this.followMouse.y -= 0.1 * (this.followMouse.y - this.mouse.y)
    this.prevMouse.x = this.mouse.x
    this.prevMouse.y = this.mouse.y
  }

  render() {
    this.time += 0.05
    this.getSpeed()
    this.scene.children.forEach(m => {
      if (m.material.uniforms) {
        m.material.uniforms.angle.value = 0.3;
        m.material.uniforms.time.value = this.time;
      }
    })
    this.customPass.fullscreenMaterial.uniforms.time.value = this.time
    this.customPass.fullscreenMaterial.uniforms.uMouse.value = this.followMouse
    this.customPass.fullscreenMaterial.uniforms.uVelo.value = Math.min(this.targetSpeed, 0.05) // limit speed
    // this.customPass.fullscreenMaterial.uniforms.uWidthCoef.value = (this.width % 70)
    this.targetSpeed *= 0.999 // was 0.999
    if (this.composer) this.composer.render()
  }

  animate() {
    this.render()
    // loop..
    requestAnimationFrame(() => this.animate())
  }

  fragmentShader() {
    return `
      uniform float time;
      uniform float progress;
      uniform sampler2D texture1;
      uniform vec4 resolution;
      varying vec2 vUv;

      void main() {
        vec2 newUV = (vUv - vec2(0.5))*resolution.zw + vec2(0.5);
        // newUV.x += 0.02*sin(newUV.y*20. + time);
        gl_FragColor = texture2D(texture1,newUV);
      }
    `;
  }

  vertexShader() {
    return `
      uniform float time;
      uniform float progress;
      uniform vec4 resolution;
      varying vec2 vUv;
      uniform sampler2D texture1;

      const float pi = 3.1415925;

      void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0 );
      }
    `
  }

  postFragmentShader() {
    return `
      uniform float time;
      uniform float progress;
      uniform sampler2D tDiffuse;
      uniform vec2 resolution;
      varying vec2 vUv;
      uniform vec2 uMouse;
      uniform float uVelo;
      uniform float uRadius;
      uniform int uType;
      uniform float uPower;
      uniform sampler2D texture1;

      float circle(vec2 uv, vec2 disc_center, float disc_radius, float border_size) {
        uv -= disc_center;
        uv*=resolution;
        float dist = sqrt(dot(uv, uv));
        return smoothstep(disc_radius+border_size, disc_radius-border_size, dist);
      }

      float map(float value, float min1, float max1, float min2, float max2) {
        return min2 + (value - min1) * (max2 - min2) / (max1 - min1);
      }

      float remap(float value, float inMin, float inMax, float outMin, float outMax) {
        return outMin + (outMax - outMin) * (value - inMin) / (inMax - inMin);
      }

      float hash12(vec2 p) {
        float h = dot(p,vec2(127.1,311.7)); 
        return fract(sin(h)*43758.5453123);
      }

      // #define HASHSCALE3 vec3(.1031, .1030, .0973)
      vec2 hash2d(vec2 p)
      {
        vec3 p3 = fract(vec3(p.xyx) * vec3(.1031, .1030, .0973));
          p3 += dot(p3, p3.yzx+19.19);
          return fract((p3.xx+p3.yz)*p3.zy);
      }

      void main() {
        vec2 newUV = vUv;
        vec4 color = vec4(1.,0.,0.,1.);
        
        // colorful
        if(uType==0){
          float c = circle(newUV, uMouse, 0.0, 0.2);
          float r = texture2D(tDiffuse, newUV.xy += c * (uVelo * .5)).x;
          float g = texture2D(tDiffuse, newUV.xy += c * (uVelo * .525)).y;
          float b = texture2D(tDiffuse, newUV.xy += c * (uVelo * .55)).z;
          color = vec4(r, g, b, 1.);
        }

        // zoom
        if(uType==1){
          float c = circle(newUV, uMouse, uRadius, 0.1+uVelo*2.)*40.*uVelo;
          vec2 offsetVector = normalize(uMouse - vUv);
          vec2 warpedUV = mix(vUv, uMouse, c * uPower); //power - was 0.5
          color = texture2D(tDiffuse, warpedUV) + texture2D(tDiffuse, warpedUV)*vec4(vec3(c), 1.);
        }

        // random
        if(uType==2){
            float hash = hash12(vUv*2.5);
            float c = circle(newUV, uMouse, uRadius, 0.1+uVelo*0.08)*5.*uVelo;
            vec2 offsetVector = normalize(uMouse - vUv);
            vec2 warpedUV = vUv + vec2(hash - uPower)*c; //power - was 0.1
            color = texture2D(tDiffuse, warpedUV) + texture2D(tDiffuse, warpedUV)*vec4(vec3(c), 1.);
        }

        // water
        if(uType==3){
          float c = -circle(newUV, uMouse, uRadius, 0.1+uVelo*2.)*10.*uVelo;
          vec2 offsetVector = -normalize(uMouse - vUv);
          vec2 warpedUV = mix(vUv, uMouse, c * uPower); 
          vec2 warpedUV1 = mix(vUv, uMouse, c * uPower); 
          vec2 warpedUV2 = mix(vUv, uMouse, c * uPower); 
          color = vec4(
           texture2D(tDiffuse,warpedUV ).r,
           texture2D(tDiffuse,warpedUV1 ).g,
           texture2D(tDiffuse,warpedUV2 ).b,
           texture2D(tDiffuse,warpedUV2 ).a
          );
        }

        gl_FragColor = color;
      }
    `
  }

  postVertexShader() {
    return `
      uniform float time;
      uniform float progress;
      uniform vec2 resolution;
      varying vec2 vUv;
      uniform sampler2D texture1;

      const float pi = 3.1415925;

      void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0 );
      }
    `
  }

  // https://codesandbox.io/s/threejs-ripple-text-distortion-effect-wlt94?file=/src/WaterEffect.js:350-374
  postFragmentShader2() {
    return `
      uniform float time;
      uniform float progress;
      uniform sampler2D uDataTexture;
      uniform sampler2D uTexture;

      uniform vec4 resolution;
      varying vec2 vUv;
      varying vec3 vPosition;
      float PI = 3.141592653589793238;
      void main() {
        vec2 newUV = (vUv - vec2(0.5))*resolution.zw + vec2(0.5);
        vec4 color = texture2D(uTexture,newUV);
        vec4 offset = texture2D(uDataTexture,vUv);
        gl_FragColor = vec4(vUv,0.0,1.);
        gl_FragColor = vec4(offset.r,0.,0.,1.);
        gl_FragColor = color;
        gl_FragColor = texture2D(uTexture,newUV - 0.02*offset.rg);
        // gl_FragColor = offset;

      }
    `
  }

  postVertexShader2() {
    return `
      uniform float time;
      varying vec2 vUv;
      varying vec3 vPosition;
      uniform vec2 pixels;
      float PI = 3.141592653589793238;
      void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
      }
    `
  }

  vertexParticles() {
    return `
      uniform float time;
      varying vec2 vUv;
      varying vec3 vPosition;
      uniform sampler2D texture1;
      float PI = 3.141592653589793238;
      void main() {
        vUv = uv;
        vec4 mvPosition = modelViewMatrix * vec4( position, 1. );
        gl_PointSize = 1000. * ( 1. / - mvPosition.z );
        gl_Position = projectionMatrix * mvPosition;
      }
    `
  }
  // stop() {
  //   this.paused = true
  // }

  // play() {
  //   this.paused = false
  //   this.render()
  // }
}
