<template>  
  <div class="project-page">
    <div class="description-fixed-col">
      <h2 v-text="data.title" ref="h1"></h2>
      <div v-html="data.description" ref="description" class="project-page-description"></div>
      <span v-if="data.nextSibling" @click="$router.push({ name: 'Project', params: { slug: data.nextSibling }, query: { r: 1 } })">Next project</span>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-auto"><div class="project-description"></div></div>
        <div class="col project-images-col">
          <div v-for="(item,index) in data.imagessets" 
            :key="index" 
            class="portfolio-item"
            :class="index === 0 ? '' : (this.$store.enterFlipElement ? '-hidden' : '')" 
            :ref="index === 0 ? '' : 'images'">
            <div v-if="item.sourceFile">
              <picture>
                <source 
                  v-for="bpoint in Object.keys(item)" 
                  :key="bpoint"
                  :srcset="item[bpoint].id ? false : `${item[bpoint].size1x.webp} 1x, ${item[bpoint].size2x.webp} 2x`"       
                  type="image/webp" 
                  :media="bpoint === `default` ? `(max-width: 767px)` : `(min-width: ${bpoint.replace('min', '')}px)`">
                <source 
                  v-for="bpoint in Object.keys(item)" 
                  :key="bpoint"
                  :srcset="item[bpoint].id ? false : `${item[bpoint].size1x.jpg} 1x, ${item[bpoint].size2x.jpg} 2x`" 
                  type="image/jpeg" 
                  :media="bpoint === 'default' ? `(max-width: 767px)` : `(min-width: ${bpoint.replace('min', '')}px)`">
                <img 
                  :src="item.default.size1x.jpg" 
                  :alt="item.sourceFile.title" 
                  crossOrigin="*" 
                  :ref="index === 0 ? 'flipTargetEl' : ''"
                  :data-flip-id="index === 0 ? flipId : ''">
              </picture>
            </div>
            <div class="responsive-video" v-if="item.path">
              <Waypoint @change="onChange" />
              <video class="lazy" 
                preload="none"
                :poster="item.poster"
                :ref="index === 0 ? 'flipTargetEl' : ''"
                :data-flip-id="index === 0 ? flipId : ''"
                muted=""
                loop="loop">
                <source :src="item.path" type="video/mp4">
              </video>
            </div>
          </div>
        </div>
        </div>
    </div>
  </div>
</template>

<script>
import { Waypoint } from 'vue-waypoint'
import router from '@/router'
import api from '@/api'
import appConfig from '@/app.config.json'
// import { useRoute, onBeforeRouteLeave, onBeforeRouteUpdate } from 'vue-router'

// export const retry = (assertion, { interval = 20, timeout = 1000 } = {}) => {
//   return new Promise((resolve, reject) => {
//     const startTime = Date.now()

//     const tryAgain = () => {
//       setTimeout(() => {
//         try {
//           resolve(assertion())
//         } catch (err) {
//           Date.now() - startTime > timeout ? reject(err) : tryAgain()
//         }
//       }, interval)
//     };

//     tryAgain()
//   });
// }

export default {
  name: 'ProjectPage',
  data () {
    return {
      data: {
        title: '',
        description: '',
        nextSibling: '',
        imagessets: {}
      },
      flipId: 'auto'
    }
  },
  page: {
    title: 'Sharon Tan projects'
  },
  components: {    
    Waypoint
  },
  setup() {
    // const route = useRoute()

    const onChange = (waypointState) => {      
      if (waypointState.going === 'IN' && waypointState.el !== null) {
        const video = waypointState.el.parentNode.querySelector('video')
        video.play()
      }
    }

    return { onChange }
  },  
  methods: {
    async fetchDetails(slug) {
      const params = new URLSearchParams()
      params.append('slug', slug)

      await api.get('/pages', {
        params: params
      }).then(response => {
        if (response.data.error === '404') {
          router.push({name: 'PageNotFound' })
        }
        const pageData = response.data[0]
        if (pageData.description.length) {
          this.data.description = pageData.description
        }
        this.data.title = pageData.title
        this.data.nextSibling = pageData.nextSibling
        
        if (typeof pageData.imagessets !== 'undefined') {
          this.data.imagessets = pageData.imagessets
        }
      })
    },
    descriptionAppear(done) {
      const interval = setInterval(() => {
        if (this.$refs.description) {
          let targets = [this.$refs.h1, this.$refs.description]

          const that = this  
          this.gsap.fromTo(targets, {
            y: 50,
            autoAlpha: 0              
          },{
            y: 0,
            ease: 'Power0.easeNone',
            // delay: 2,
            duration: 0.75,
            stagger: 0.5,
            autoAlpha: 1,
            onComplete() {
              if (that.$refs.h1 && that.$refs.h1.parentNode) {
                that.$refs.h1.parentNode.style.overflow = 'auto'
              }
              done()
            }
          })
          clearInterval(interval)        
        }
      }, 50)
    },
    flipImage(done) {
      const body = document.querySelector('body')
      body.style.height = '100vh'
      body.style.overflow = 'hidden'
      const interval = setInterval(() => {
        if (this.$refs.flipTargetEl) {
          document.querySelector('.wrapper').classList.remove('-black-bg')
          const elemToFlip = this.$refs.flipTargetEl[0]       
          const obj = this
          const wrapper = document.querySelector('.wrapper')

          if (elemToFlip && this.$store.enterFlipElement) { // && this.$store.flipElementState
            const fromEl = this.$store.enterFlipElement
            wrapper.appendChild(fromEl) 
            this.flipId = fromEl.dataset.flipId
            this.gsap.set(fromEl, this.$store.fromState)
            const fromState = this.Flip.getState(fromEl)
            this.gsap.set(fromEl, { visibility: 'hidden' })
            requestAnimationFrame(() => this.Flip.from(fromState, {
              targets: elemToFlip,
              ease: 'power2.in',
              delay: 1.2,
              duration: 0.5,
              scale: true,
              onComplete() {
                body.style.height = '100%'
                body.style.overflow = ''
                obj.$store.enterFlipElement = null
                obj.$store.fromState = null
                obj.descriptionAppear(done)
                obj.flipAllImages()
              }
            }))            
          }      

          clearInterval(interval)
        }
      }, 50)      
    },
    flipAllImages() {
      const elsToFlip = this.$refs.images
      if (typeof elsToFlip === 'undefined') return

      const state = this.Flip.getState(elsToFlip)
      for (const el of elsToFlip) {
        el.classList.remove('-hidden')
      }
      const flip = this.Flip.from(state, {
        duration: 2,
        scale: false,
        absolute: true
      })  
      return flip
    }
  },
  beforeMount() {
    this.fetchDetails(this.$route.params.slug)
  },
  mounted() {
    const videos = document.querySelectorAll('video')
    for (let i = videos.length - 1; i >= 0; i--) {
      videos[i].pause()
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params.slug !== from.params.slug) {
      this.fetchDetails(to.params.slug)
    }
  }
}
</script>
