import axios from 'axios';
import router from '@/router'
import appConfig from '@/app.config.json'

const api = axios.create({
  'baseURL': process.env.NODE_ENV === 'production' ? appConfig.apiUrl : appConfig.apiUrlLocal,
  headers: {
    'Accept': 'text/plain',
    'Content-Type': 'application/x-www-form-urlencoded'
    // 'Access-Control-Max-Age': 600
  }
});

api.interceptors.response.use(
  response => response, 
  error => {
    let path = '/503';
    console.log(error, error.response);
    // if (typeof error.response === 'undefined') {
    //   path = '/503';
    // } else {
    //   switch (error.response.status) {
    //     // case 503: path = '/503'; break;
    //     case 404: path = '/404'; break;
    //   }    
    // }
    // router.push(path);
    // return Promise.reject(error);
  });

export default api;