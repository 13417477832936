<template>
  <div class="page">    
    <projects-list :projects="projects"/>
  </div>
</template>

<script>

import ProjectsList from '@/components/ProjectsList'
import api from '@/api'

export default {
  name: 'HomePage',
  page: {
    title: 'Home'
  },
  data () {
    return {
      projects: []
    }
  },
  components: {
    ProjectsList
  },
  computed: {
    axiosParams() {
      const params = new URLSearchParams()
      params.append('parent_slug', 'portfolio')
      return params
    }
  },
  mounted () {
    api.get('/pages', {
      params: this.axiosParams
    }).then(response => {
      this.projects = response.data
    })
  }
}
</script>
