<template>
  <div>
    <flowers-falling/>  
    <greetings-list/>
    <div class="container-fluid about-page" v-html="data.description" ref="description"></div>
    <footer-block ref="footer"/>
  </div>
</template>

<script>
import api from '@/api'
import FlowersFalling from '@/components/Flowers'
import GreetingsList from '@/components/GreetingsList'
import FooterBlock from '@/components/FooterBlock'

export default {
  name: 'SimplePage',
  page: {
    title: 'Terms and conditions'
  },
  components: {
    GreetingsList,
    FlowersFalling,
    FooterBlock
  },
  data () {
    return {
      data: {}
    }
  },
  computed: {
    axiosParams() {
      const params = new URLSearchParams();
      params.append('slug', 'terms');
      return params;
    }
  },
  mounted () {
    api.get('/pages', {
      params: this.axiosParams
    }).then(response => {
      this.data = response.data[0]
    })

    // const interval = setInterval(() => {      
    //   const target = this.$refs.description;
    //   if (target && target.children.length && this.$refs.footer) {

    //     this.gsap.fromTo(target.children,{
    //         y: 50,
    //         autoAlpha: 0              
    //       }, {
    //         y: 0,
    //         ease: 'Power0.easeNone',
    //         duration: 0.8,
    //         autoAlpha: 1,
    //         stagger: 0.5,
    //         delay: 3.8
    //       }
    //     )

    //     clearInterval(interval)

    //     setTimeout(() => {
    //       document.querySelector('.wrapper').classList.add('-show-all-content')
    //     }, 4300)
    //   }
    // }, 50)
  },
  methods: {
  }
}
</script>
