<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <h1 class="footer-title">Expertise</h1>
        <div class="col-md-4">
          <ul class="expertise-list">
            <li>Art Direction</li>
            <li>Brand Identity</li>
            <li>Fashion</li>
          </ul>
        </div>
        <div class="col-md-4">
          <ul class="expertise-list">
            <li>Illustration</li>
            <li>Print Design</li>
          </ul>
        </div>
        <div class="col-md-4">
          <ul class="expertise-list">
            <li>Product Design</li>
            <li>Typography</li>
            <li>UI/UX</li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterBlock'
}
</script>
