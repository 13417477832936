import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import ProjectView from '../views/ProjectView.vue'
import ContactView from '../views/ContactView.vue'
import SimpleView from '../views/SimpleView.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta: {
      mode: '',
      name: 'Sharon Tan art & design director',
      description: 'Sharon Tan art & design director'
    }
  },
  {
    path: '/about',
    name: 'About',
    component: AboutView,
    meta: {
      mode: 'out-in',
      name: 'Sharon Tan art & design director',
      description: 'Sharon Tan art & design director'
    }
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: ContactView,
    meta: {
      mode: 'out-in',
      name: 'Sharon Tan contacts',
      description: 'Sharon Tan art & design director'
    }
  },
  {
    path: '/portfolio/:slug',
    name: 'Project',
    component: ProjectView,
    meta: {
      mode: '',
      name: 'Sharon Tan projects',
      description: 'Sharon Tan art & design director'
    }
  },
  {
    path: '/terms',
    name: 'Terms',
    component: SimpleView,
    meta: {
      mode: '',
      name: 'Sharon Tan projects',
      description: 'Sharon Tan art & design director'
    }
  },
  {
    path: '/404',
    name: 'PageNotFound',
    component: () => import('@/views/PageNotFound.vue'),
    meta: {
      mode: '',
      name: '404',
      description: '404'
    }
  },
  {
    path: '/503',
    name: 'TemporarilyUnavailable',
    component: () => import('@/views/TemporarilyUnavailable.vue'),
    meta: {
      mode: '',
      name: '503',
      description: '503'
    }
  },
  {
    path: "/:catchAll(.*)", // Unrecognized path automatically matches 404
    redirect: '/404'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.name
  next()
})

export default router
