import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import HeaderBlock from '@/components/HeaderBlock'
import '@/assets/css/main.min.css'
import axios from 'axios'
import appConfig from '@/app.config.json'
import VueLazyload from 'vue-lazyload'
import gsap from 'gsap'
import { Flip } from 'gsap/Flip';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
// import { Image } from "@unpic/vue";
gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(Flip)

const loadimage = require('@/assets/images/loading.gif')
const errorimage = require('@/assets/images/error.gif')

axios.interceptors.response.use( 
  (response) => {
    return response
  },
  (error) => {
    if (error.response.status === 404) {
      router.push({name: 'PageNotFound' })
    } else {
      return Promise.reject(error)
    }
  }
)

const app = createApp(App)

app.config.globalProperties.gsap = gsap
app.config.globalProperties.Flip = Flip
app.config.globalProperties.ScrollTrigger = ScrollTrigger
app.config.globalProperties.axios = axios
app.config.globalProperties.apiUrl = process.env.NODE_ENV === 'production' ? appConfig.apiUrl : appConfig.apiUrlLocal

app.use(store)
app.use(router)
app.use(VueLazyload, {
  preLoad: 1.3,
  error: errorimage,
  loading: loadimage,
  attempt: 1
})

app.component('HeaderBlock', HeaderBlock).mount('#app')
