<template>
  <div class="eye" ref="eye" id="eyeWrapper">
    <svg id="eyeSvg" viewBox="-2 -2 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M95 49C95 42.6796 88.1862 36.084 75.3962 37.4106C85.3587 29.111 85.6092 19.5272 81.2341 15.059C76.8591 10.5908 67.4734 10.8466 59.3486 21.0213C60.6458 7.95895 54.1877 1 47.9991 1C41.8105 1 35.3525 7.95895 36.6514 21.0213C28.5248 10.8466 19.1409 10.5908 14.7659 15.059C10.3908 19.5272 10.6413 29.1128 20.6038 37.4106C7.81385 36.0858 1 42.6814 1 49C1 55.3186 7.81385 61.916 20.6038 60.5894C10.6413 68.889 10.3908 78.4728 14.7659 82.941C19.1409 87.4092 28.5266 87.1534 36.6514 76.9787C35.3525 90.0411 41.8123 97 47.9991 97C54.186 97 60.6458 90.0411 59.3469 76.9787C67.4734 87.1534 76.8574 87.4092 81.2324 82.941C85.6074 78.4728 85.357 68.8873 75.3944 60.5894C88.1844 61.916 94.9983 55.3186 94.9983 49H95Z" stroke="#fdf9f2" stroke-width="4" stroke-miterlimit="10"/>
      <g id="eye">
        <path d="M47.5 66C56.6127 66 64 58.3888 64 49C64 39.6112 56.6127 32 47.5 32C38.3873 32 31 39.6112 31 49C31 58.3888 38.3873 66 47.5 66Z" stroke="#fdf9f2" stroke-width="4" stroke-miterlimit="10"/>        
        <circle cx="48" cy="58" r="8" fill="#fdf9f2"/>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'EyeBlock',
  data () {
    return {
      requestId: null,
      mouse: {},
      svg: null,
      eye: null
    }
  },
  mounted () {
    this.svg = document.querySelector('#eyeSvg')
    this.mouse = this.svg.createSVGPoint()
    this.eye = this.createEye('#eye')
    window.addEventListener('mousemove', this.onMouseMove)
  },
  methods: {
    onFrame() {      
      const point = this.mouse.matrixTransform(this.svg.getScreenCTM().inverse())
      this.eye.rotateTo(point)

      this.requestId = null
    },
    onMouseMove(event) {
      this.mouse.x = event.clientX
      this.mouse.y = event.clientY

      if (!this.requestId) {
        this.requestId = requestAnimationFrame(this.onFrame)
      }
    },
    createEye(selector) {  
      const element = document.querySelector(selector)
      const gsap = this.gsap
      gsap.set(element, {
        transformOrigin: 'center'
      })
      
      const bbox = element.getBBox()
      
      const centerX = bbox.x + bbox.width / 2
      const centerY = bbox.y + bbox.height / 2
      
      function rotateTo(point) {        
        const dx = point.x - centerX
        const dy = point.y - centerY
        
        const radian = Math.atan2(-dy, dx)
        const angle = (radian * (180 / Math.PI) * -1) + 270
        gsap.to(element, {
          duration: 0.3,
          rotation: angle
        })
      }
      
      return {
        element: element,
        rotateTo: rotateTo
      }
    }
  }
}
</script>
