<template>
  <picture>
    <source 
      v-for="bpoint in Object.keys(srcset)" 
      :key="bpoint"
      :srcset="`${srcset[bpoint].size1x.webp} 1x, ${srcset[bpoint].size2x.webp} 2x`"       
      type="image/webp" 
      :media="bpoint === `default` ? `(max-width: 767px)` : `(min-width: ${bpoint.replace('min', '')}px)`">
    <source 
      v-for="bpoint in Object.keys(srcset)" 
      :key="bpoint"
      :srcset="`${srcset[bpoint].size1x.jpg} 1x, ${srcset[bpoint].size2x.jpg} 2x`" 
      type="image/jpeg" 
      :media="bpoint === 'default' ? `(max-width: 767px)` : `(min-width: ${bpoint.replace('min', '')}px)`">
    <img 
      :src="imageSizes.default.size1x.jpg" 
      :alt="imageSizes.sourceFile.title" 
      :data-flip-id="`flip-image-${projectSlug}`"
      crossOrigin="*" 
      class="projects-marquee-image -opacity0" 
      ref="imagehover" 
      @load="$emit('img-load')" 
      @click.left="$emit('img-click', $event)">
  </picture>
</template>

<script>
export default {  
  name: 'ImageSet',
  props: {
    imageSizes: {
      type: Array,
      default: () => []
    },
    projectSlug: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    srcset: function() {
      return Object.keys(this.imageSizes).filter(key => key !== 'sourceFile').reduce((obj, key) => {
        obj[key] = this.imageSizes[key]
        return obj
      }, {})
    }
  }
}
</script>
