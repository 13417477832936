<template>
  <div id="black-curtain" class="black-curtain">    
    <div class="loading-timer" v-text="loaded"></div>
  </div>
  <main class="wrapper" :class="getPageName">
    <header-block :showEye="showEye"/>
    <router-view v-slot="{ Component }">
      <transition 
        mode="out-in"
        @enter="onEnter"
        @before-leave="onBeforeLeave"
        @leave="onLeave"
        :css="false">
        <component :is="Component" ref="currentView"></component>

      </transition> 
    </router-view>
  </main>  
</template>

<script>
export default {
  data() {
    return {
      loadingPercent: 0,
      loadTime: 0,
      interval: null,
      curtainInOutTime: 0.8
    }
  },
  created() {
    let perfData = window.performance.timing
    let estimatedTime = Math.abs(perfData.loadEventEnd - perfData.navigationStart)
    this.loadTime = parseInt((estimatedTime / 1000) % 60) * 100
    this.doProgress()
  },
  computed: {
    getPageName() {
      const pageName = this.$router.currentRoute.value.name
      if (typeof pageName !== 'undefined') {
        return ('-' + pageName.toLowerCase())
      }
      return null
    },
    showEye() {
      const pageName = this.$router.currentRoute.value.name
      if (typeof pageName !== 'undefined') {
        return ['About','Contacts'].includes(pageName)
      }
      return false
    },
    loaded() {
      return this.loadingPercent + '%'
    }
  },
  methods: {
    doProgress() {
      let step = this.loadTime / 100;
      this.interval = setInterval(() => {
        this.loadingPercent++
        if (this.loadingPercent > 100) {
          this.loadingPercent = 100
        }
      }, step);
      // console.log('do progress')
    },
    fakeProgress() {      
      let step = 8;
      this.loadingPercent = 0
      const interval = setInterval(() => {
        this.loadingPercent++
        if (this.loadingPercent > 100) {
          this.loadingPercent = 100
        }
        if (this.loadingPercent >= 100) {
          clearInterval(interval)
        }
      }, step);
      // console.log('fake progress')
    },
    onLeave(el, done) {
      // Project flip
      const leaveFlipElement = this.$store.leaveFlipElement
      if (leaveFlipElement !== null && typeof leaveFlipElement !== 'undefined') {
        this.animateImage(this.$store.leaveFlipElement, el, done)
        document.querySelector('.wrapper').classList.remove('-black-bg')
        return
      } 

      const flipState = this.$store.fromState
      if (flipState === null || typeof flipState === 'undefined') {
        this.leaveBlackCurtain(el.parentNode, done)
      } else { done() }
    },
    onEnter(el, done) {
      document.querySelector('.wrapper').classList.remove('-black-bg')
      if (this.$router.currentRoute.value.name === 'Project') {
        if (typeof this.$store.fromState === 'undefined') {
          this.$refs.currentView.descriptionAppear(done)
        } else {
          this.$refs.currentView.flipImage(done)
        }
      } else {
        if (typeof this.$store.fromState === 'undefined' || this.$store.fromState === null) {
          this.enterBlackCurtain(el.parentNode, done);
        } else {
          done()
        }
      }
    },
    leaveBlackCurtain(wrapper, callback) {
      // console.log(wrapper);
      this.gsap.to(wrapper, {
        ease: 'power2.out',
        duration: 0.7,
        yPercent: -100,
        onComplete: callback
      })
    },
    enterBlackCurtain(wrapper = null, callback = null) {
      if (wrapper == null) {
        wrapper = document.querySelector('.wrapper')
      }
      this.gsap.fromTo(wrapper, {
        yPercent: 100
      },{
        yPercent: 0,
        duration: 1.4,
        delay: 0.1,
        ease: 'power2.in',
        onComplete() {
          if (callback !== null) {
            callback()
          }
          wrapper.style.transform = 'none'
        }
      })
    },
    animateImage(fromEl, el, done) {
      const toEl = el.parentNode
      const clone = fromEl.cloneNode(true)
      const from = this.calculatePosition(fromEl)
      const to = this.calculatePosition(toEl)
      const fromRatio = from.height / (from.width * 0.01)
      const that = this
      const wrapper = document.querySelector('.wrapper')
      
      this.gsap.set([fromEl], { visibility: 'hidden' })
      this.gsap.set(clone, { position: 'absolute', margin: 0 })      
      wrapper.appendChild(clone) 
          
      const style = {
        x: to.left - from.left,
        y: to.top - from.top,
        width: to.width,
        height: to.width / 100 * fromRatio,
        autoRound: false,
        duration: 0.5,
        zIndex: 3,
        ease: 'power2.out',
        onComplete () {
          that.$store.enterFlipElement = clone
          that.$store.fromState = {
            x: to.left - from.left,
            y: to.top - from.top,
            width: to.width,
            height: to.width / 100 * fromRatio,
            zIndex: 3,
            autoRound: false
          }
          that.$store.leaveFlipElement = null
          // wrapper.removeChild(clone)
          done()
        }
      }

      this.gsap.set(clone, from)
      this.gsap.to(clone, style)      
    },
    calculatePosition(element) {
      const body = document.body
      const root = document.documentElement
      const rect = element.getBoundingClientRect()      
      const scrollTop = window.pageYOffset || root.scrollTop || body.scrollTop || 0;
      const scrollLeft = window.pageXOffset || root.scrollLeft || body.scrollLeft || 0;      
      const clientTop = root.clientTop || body.clientTop || 0;
      const clientLeft = root.clientLeft || body.clientLeft || 0;
        
      return {
        top: Math.round(rect.top + scrollTop - clientTop),
        left: Math.round(rect.left + scrollLeft - clientLeft),
        height: rect.height,
        width: rect.width
      }
    }
  },
  watch: {
    $route (to, from) {
      if (to.params.slug !== from.params.slug) {
        console.log('animate!');
        this.leaveBlackCurtain(document.querySelector('.wrapper'), this.enterBlackCurtain)
      }
      // if (to.name === 'Project' && typeof from.name === 'undefined') {
      //   this.fakeProgress()
      // }
      if (to.name !== 'Project') {
        this.fakeProgress()
      }
    },
    loadingPercent(val) {
      if (val >= 100) {        
        clearInterval(this.interval)
      }
    }
  }
}
</script>
