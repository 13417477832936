export default class Item {
  constructor(image, scene, position, elementId) {
    this.scene = scene
    this.elementId = elementId
    this.DOM = { el: image }
    this.currentScroll = position
    this.animated = false;
    this.isBeingAnimatedNow = false;
    this.shouldRollBack = false;
    this.shouldUnRoll = false;
    this.positions = [];
    // set initial sizes
    this.getSize()

    this.mesh = scene.createMesh({
      width: this.width,
      height: this.height,
      src: this.DOM.el.currentSrc,
      image: this.DOM.el,
      iWidth: this.DOM.el.width,
      iHeight: this.DOM.el.height
    })
    scene.scene.add(this.mesh)
    this.mesh.position.x = 0//-7.5
    this.mesh.position.y = 0
    window.addEventListener('resize', () => this.resize())
    // this.setX()
  }

  getSize() {
    const bounds = this.DOM.el.getBoundingClientRect()
    this.width = bounds.width
    this.height = bounds.height
    this.left = bounds.left
  }

  resize() {
    this.getSize()
    this.mesh.scale.set(this.width, this.height, 200)
    // this.setX()
  }

  // setX() {
  //   const scene = this.scene
  //   const shift = this.elementId === 'canvasWrapper2' ? scene.width : 0
  //   this.mesh.position.x = 0 - scene.width / 2 + this.left + this.width / 2 - shift
  // }
}